import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sceneBrowserMapStateToProps } from "mapToProps/sceneEditor";
import ContentContainer from "modules/ContentContainer";
import TopBar from "components/TopBar";
import { SceneSlideshow, CardBrowser, Dialog } from "@terraincognita/ui-core";
import LayoutSelector from "modules/StoryEditor/LayoutSelector";
import * as storyActions from "actions/storyEditor";
import * as sceneActions from "actions/sceneEditor";
import "./styles.scss"; // need to leverage the same selector actions from story

/**
 * SceneBrowser
 * card browser for scenes
 */
class SceneBrowser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstLoad: true,
      deleteModalOpen: false,
      layoutSelectorOpen: false
    };

    // NOTE: the bindings allow for use of this reference
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.proceedToDelete = this.proceedToDelete.bind(this);
    this.closeLayoutSelector = this.closeLayoutSelector.bind(this);
  }

  closeAddModal() {
    this.setState({ addModalOpen: false });
  }

  proceedToAdd() {
    this.setState({ layoutSelectorOpen: true });
  }

  openDeleteModal() {
    this.setState({ deleteModalOpen: true });
  }

  closeDeleteModal() {
    this.setState({ deleteModalOpen: false });
  }

  proceedToDelete(params) {
    this.setState({ deleteModalOpen: false });
    // this.props.setBrowserIsLoading(true); //NOTE: is this correct?
    this.props.deleteScene(); // how do we pass IDS to delete
  }

  render() {
    const {
      userPreferences,
      cardBrowserConfig, // configuration props
      // storyBrowserUIState, //
      isLoading,
      scenes,
      sidebarMode,
      storyID,
      storyData,
      slugBase,
      needsSaving,
      // functions
      goBackAction,
      // setactiveEditingScene,
      duplicateSlide,
      deleteSlide,
      updateSlide,
      revertSlide,
      setSceneOrder,
      // onSortEnd,
      selectHandler,
      exclusiveSelectHandler,
      editClickHandler,
      backClickHandler,
      // setSliderMultiplier,
      onSortByOrderClick,
      onSortByClick,
      handleMoreMenuChange
    } = this.props;

    if (isLoading) {
      return <div>loading scenes to browse....</div>;
    }
    const topBar = (
      <TopBar
        cardBrowserConfig={cardBrowserConfig}
        isSliderDisabled={true}
        // setSliderMultiplier={setSliderMultiplier}
        exclusiveSelectHandler={exclusiveSelectHandler}
        proceedToAdd={() => {
          this.proceedToAdd();
        }}
        openDeleteModal={() => {
          this.openDeleteModal();
        }}
      />
    );

    const CardSlideshowScene = ({ item }) => {
      const {
        userPreferences,
        accountProjectDefaults,
        storyID,
        storyClasses,
        elements,
        classNames,
        styles,
        layout,
        layoutName
      } = item;

      const previewClassName = userPreferences
        ? `preview-${userPreferences.preview || "dm"}`
        : `preview-dm`;

      const themeStyleSheet = storyData.themeId
        ? `dest-theme-${storyData.themeId}.css`
        : `dest-theme-${accountProjectDefaults.theme.themeId}.css`;

      return (
        <div className={`slide-thumb ${previewClassName}`}>
          <Helmet>
            <link
              rel="stylesheet"
              href={"https://storycrafter.co/sc-structure-sc4.css"}
            />

            <link
              rel="stylesheet"
              href={`https://storycrafter.co/${themeStyleSheet}`}
            />
          </Helmet>
          <div
            className={`slide-thumb-container scene-container content ${previewClassName} ${
              storyClasses ? storyClasses : ""
            }`}
          >
            <SceneSlideshow
              useContext="scene-browser"
              elements={elements}
              backgroundColor={
                elements && elements.background
                  ? elements.background.color
                  : undefined
              }
              classNames={classNames || ""}
              styles={styles || {}}
              layout={layoutName}
              layoutElements={layout.elements}
              template={layout.template}
              isEditMode
              playAsModal={false}
              storyProperties={storyData}
            />
          </div>
        </div>
      );
    };

    const moreMenuOptions = {
      duplicate: {
        label: "Duplicate Slide",
        value: "duplicate"
      }
      // delete: {
      //   label: "Delete Slide",
      //   value: "delete"
      // }
    };

    const previewClassName = userPreferences
      ? `preview-${userPreferences.preview || "dm"}`
      : `preview-dm`;

    return (
      <div className={`scene-browser ${previewClassName}`}>
        <ContentContainer
          backgroundColor="white"
          isLoading={isLoading}
          className="content-container"
          sidebarMode={sidebarMode}
          selectMode={cardBrowserConfig.selectMode}
          title="Slides"
          topBar={topBar}
          goBackAction={this.props.goBackAction}
        >
          <CardBrowser
            thumbHelperClassName={`scene-browser ${previewClassName}`}
            items={scenes}
            CardContentsComponent={CardSlideshowScene}
            enableKeys={false}
            minCardSize={cardBrowserConfig.cardSizeMultiplier}
            showButtons
            setCardMargin={20}
            isSequentiable={!cardBrowserConfig.selectMode}
            executeOnReSort={setSceneOrder}
            selectMode={cardBrowserConfig.selectMode}
            selected={cardBrowserConfig.selected}
            selectHandler={selectHandler}
            cardStyle={{
              backgroundColor: "whitesmoke",
              titleBackgroundColor: "whitesmoke",
              titleColor: "black",
              imageFit: "contain"
            }}
            editClickHandler={editClickHandler}
            exclusiveSelectHandler={exclusiveSelectHandler}
            backClickHandler={backClickHandler}
            moreMenuOptions={moreMenuOptions}
            handleMoreMenuChange={handleMoreMenuChange}
          />
        </ContentContainer>
        <Dialog
          content="Once deleted, it cannot be restored"
          handleConfirm={() => {
            this.proceedToDelete();
          }}
          confirmLabel="Delete"
          handleCancel={() => {
            this.closeDeleteModal();
          }}
          bodyStyle={{ paddingTop: 20 }}
          cancelLabel="Cancel"
          modal={false}
          open={this.state.deleteModalOpen}
          title="Delete this Slide?"
        />
        <LayoutSelector
          open={this.state.layoutSelectorOpen}
          sceneType={this.props.sceneTypeID}
          closeAction={this.closeLayoutSelector}
          isEditMode={false}
          slideId={null}
          activeLayout={null}
          storyID={this.props.storyID}
          isModal
        />
      </div>
    );
  }

  closeLayoutSelector() {
    this.setState({ layoutSelectorOpen: false });
  }

  componentDidUpdate() {
    if (this.props.navigateToNew) {
      this.props.goToScene(this.props.navigateToNew);
    }
  }
}

SceneBrowser.defaultProps = {
  isLoading: false,
  storyBrowserUIState: {}
};

SceneBrowser.propTypes = {
  isLoading: PropTypes.bool,
  scenes: PropTypes.array,
  storyID: PropTypes.string,
  cardBrowserConfig: PropTypes.object,
  storyBrowserUIState: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  return sceneBrowserMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    storyID,
    slugBase,
    storyType,
    cardBrowserConfig,
    cardBrowserConfigUserPrefsSelector
  } = stateProps;

  // this is passed to selection function to tell it where to target config
  const cardBrowserSelector = ["storyTypes", storyType, "cardBrowserConfig"];

  return Object.assign({}, stateProps, {
    backClickHandler: id =>
      dispatchProps.exclusiveSelectToggle(id, cardBrowserSelector),
    // requestStoryData: storyID => dispatchProps.requestStoryData(storyID),
    editClickHandler: url => {
      // I think the url+ ID should be returned from the click

      ownProps.history.push(url);
    },
    goToScene: sceneID => {
      ownProps.history.push(`${slugBase}/${sceneID}`);
    },
    createScene: sceneData => {
      dispatchProps.createScene(storyID, sceneData);
    },
    deleteScene: sceneID => {
      const { selected } = cardBrowserConfig;
      dispatchProps.deleteScene(storyID, selected);
    },
    setSceneOrder: (oldIndex, newIndex) =>
      dispatchProps.setSceneOrder(storyID, oldIndex, newIndex),

    // Retaining in case we decide to allow users to resize the cards
    // setSliderMultiplier: value => {
    //   dispatchProps.dispatchUserPreferenceChange(
    //     cardBrowserConfigUserPrefsSelector,
    //     "cardSizeMultiplier",
    //     value
    //   );
    // },

    selectHandler: id => dispatchProps.selectToggle(id, cardBrowserSelector),
    exclusiveSelectHandler: id =>
      dispatchProps.exclusiveSelectToggle(id, cardBrowserSelector),
    handleMoreMenuChange: (value, sceneID) => {
      if (value === "duplicate") {
        dispatchProps.duplicateScene(storyID, sceneID);
      } else if (value === "delete") {
        dispatchProps.deleteScene(storyID, sceneID);
      }
    }
  });
}
// storyActions,
export default connect(
  mapStateToProps,
  { ...storyActions, ...sceneActions },
  mergeProps
)(SceneBrowser);
