import * as CONST from "actions/action_constants";

// depreciated...
// export const addSlide = (storyID, layoutId) => ({
//   type: CONST.STORY_EDITOR_ADD_SLIDE_REQUEST,
//   storyID,
//   layoutId,
// });

// NOTE: made this new one ..

export const slideshowSceneEditorMount = () => {
  return { type: CONST.SLIDESHOW_SCENE_EDITOR_MOUNT };
};

export const createScene = (storyID, sceneData, layoutID, isLocalized) => {
  return {
    type: CONST.SCENE_CREATE_REQUEST,
    storyID,
    sceneData,
    layoutID,
    isLocalized
  };
};

export const updateScene = (
  sceneID,
  sceneData,
  editingForm,
  sceneType,
  subsceneType,
  elementID
) =>
  // if (!storyID) return;
  ({
    type: CONST.SCENE_UPDATE_REQUEST,
    sceneID,
    sceneData,
    editingForm,
    sceneType,
    subsceneType,
    elementID
  });

export const deleteScene = (storyID, selected) => ({
  type: CONST.SCENE_DELETE_REQUEST,
  storyID,
  selected
});

export const setSceneOrder = (storyID, oldIndex, newIndex) => {
  return {
    type: CONST.STORY_SET_SCENE_ORDER_REQUEST,
    storyID,
    oldIndex,
    newIndex
  };
};

export const createSubScene = (
  storyID,
  sceneID,
  sceneData,
  sceneType,
  subsceneType,
  isRotationGallery
) => {
  return {
    type: CONST.SUBSCENE_CREATE_REQUEST,
    storyID,
    sceneID,
    sceneData,
    sceneType,
    subsceneType,
    isRotationGallery
  };
};

export const setSubSceneOrder = (
  sceneID,
  oldIndex,
  newIndex,
  isRotationGallery
) => {
  return {
    type: CONST.SCENE_SET_SUBSCENE_ORDER_REQUEST,
    sceneID,
    oldIndex,
    newIndex,
    isRotationGallery
  };
};

// I don't think we need this, i thinkwe can use the updateScene
export const updateSubscene = subsceneData => {
  return {
    type: CONST.SUBSCENE_UPDATE_REQUEST,
    subsceneData
  };
};

export const deleteSubScene = (
  parentSceneID,
  selected,
  isRotationGallery = false
) => ({
  type: CONST.SUBSCENE_DELETE_REQUEST,
  parentSceneID,
  selected,
  isRotationGallery
});

export const duplicateScene = (storyID, sceneID) => ({
  type: CONST.SCENE_DUPLICATE_REQUEST,
  storyID,
  sceneID
});

export const createSceneRevertCopy = sceneData => ({
  type: CONST.SCENE_CREATE_REVERT_COPY,
  sceneData
});

export const revertScene = (storyID, sceneID) => ({
  type: CONST.SCENE_REVERT,
  storyID,
  sceneID
});

//-------------------------
// Elements

export const setDataFromInput = (
  property,
  value,
  storyID,
  sceneID,
  elementID
) => ({
  type: CONST.STORY_EDITOR_SET_ELEMENT_VALUE_REQUEST, // CONST.STORY_EDITOR_SET_ELEMENT_VALUE_SUCCESS,
  value,
  property,
  storyID,
  sceneID,
  elementID
});

export const changeLayout = (storyID, sceneID, layoutID) => ({
  type: CONST.SCENE_CHANGE_LAYOUT_REQUEST,
  storyID,
  sceneID,
  layoutID
});

// export const changeClassNames = (storyID, sceneID, classNames) => ({
//   type: CONST.SCENE_CHANGE_CLASS_NAMES_REQUEST,
//   storyID,
//   sceneID,
//   classNames
// });

export const setElementInput = (
  property,
  value,
  language,
  sceneID,
  isRoot,
  isAssetMetaconfig,
  isLocalized,
  form
) => {
  return {
    type: CONST.SCENE_SET_INPUT_ELEMENT_REQUEST,
    value,
    language,
    property,
    sceneID,
    isRoot,
    isAssetMetaconfig,
    isLocalized,
    form
  };
};

export const setActiveEditingElement = activeElement => ({
  type: CONST.STORY_EDITOR_SET_ACTIVE_ELEMENT,
  activeElement
});

// -----------------------------
// Scene and Subscene Browser Related
export function selectToggle(id, selector) {
  return {
    type: CONST.STORY_BROWSER_SELECT_TOGGLE,
    id,
    selector
  };
}

export function exclusiveSelectToggle(id, selector) {
  return {
    type: CONST.STORY_BROWSER_EXCLUSIVE_SELECT_TOGGLE,
    id,
    selector
  };
}

export const setNavigateToNew = value => ({
  type: CONST.STORY_EDITOR_SET_NAVIGATE_TO_NEW,
  value
});

export const sceneSetAssetElement = (sceneID, elementID, assetID, section) => ({
  type: CONST.SCENE_SET_ASSET_ELEMENT_REQUEST,
  sceneID,
  elementID,
  assetID,
  section
});

export const sceneDeleteAssetElement = (sceneID, elementID) => ({
  type: CONST.SCENE_DELETE_ASSET_ELEMENT_REQUEST,
  sceneID,
  elementID
});

export const sceneSetIsSaved = (sceneID, isSaved) => ({
  type: CONST.SCENE_SET_IS_SAVED,
  sceneID,
  isSaved
});

export function setStoryBrowserIsSaved(value) {
  return {
    type: CONST.SET_STORY_BROWSER_ISSAVED,
    value
  };
}

export const changeLanguage = langID => ({
  type: CONST.CHANGE_LANGUAGE,
  langID
});

export const dispatchUserPreferenceChange = (selector, name, value) => ({
  type: CONST.USER_PREFERENCE_CHANGE,
  selector,
  name,
  value
});

export const dispatchSceneStyleChange = (sceneID, name, value) => ({
  type: CONST.SCENE_STYLE_SETTING_CHANGE,
  sceneID,
  level: "scene",
  name,
  value
});

export const dispatchContentAreaStyleChange = (
  sceneID,
  areaID,
  name,
  value
) => ({
  type: CONST.SCENE_STYLE_SETTING_CHANGE,
  sceneID,
  level: "contentAreas",
  area: `area${areaID}`,
  name,
  value
});

export const dispatchSceneStyleSettingsSaveClick = sceneID => ({
  type: CONST.SCENE_STYLE_SETTINGS_SAVE_REQUEST,
  sceneID
});

// For handling story theme change
export const setStoryProp = (storyID, prop, value, isLocalized, langID) => ({
  type: CONST.STORY_SET_PROP,
  storyID,
  prop,
  value,
  isLocalized,
  langID
});

// For handling saving story theme change
export const saveSettings = storyID => ({
  type: CONST.STORY_SETTINGS_SAVE_REQUEST,
  storyID
});
